import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Box, Button, Grid } from '@core';
import { Animation, Icon, Text } from '@components';
import { checkoutTheme } from '@theme';

import { CouponInput, CouponInputTitle } from './components';
import { GA4_EVENTS } from '@shared/constants/google';
import { trackCustomGA4Event } from '@shared/google-utils';

export const CouponRedeemField = ({ isLoading, couponCode, couponError, clickApplyHandler }) => {
  const { t } = useTranslation();
  const [couponToApply, setCouponToApply] = useState(couponCode);
  const [isCouponInputShown, setIsCouponInputShown] = useState(!!couponError);

  const isCouponApplied = () =>
    couponCode && !couponError && !isLoading && couponToApply === couponCode;

  const handleSuccess = () => {
    trackCustomGA4Event({
      eventName: GA4_EVENTS.couponButtonClick,
      params: { coupon_code: couponToApply },
    });
    setIsCouponInputShown(false);
  };

  const handleError = (error) => {
    trackCustomGA4Event({
      eventName: GA4_EVENTS.couponButtonClick,
      params: { error_title: error.code, coupon_code: couponToApply },
    });
    setIsCouponInputShown(true);
  };

  useEffect(() => {
    if (couponError) {
      setIsCouponInputShown(true);
    }
  }, [couponError]);

  return (
    <ThemeProvider theme={checkoutTheme}>
      {!isLoading &&
        (!isCouponInputShown ? (
          <Animation.FadeIn duration={200}>
            <Box
              display="flex"
              justifyContent="center"
              onClick={() => setIsCouponInputShown(true)}
              columnGap={12}
            >
              <Box display="flex">
                <Icon.Check size={20} color="success" />
              </Box>
              <Text.Body3Strong color="success" data-testid="coupon-applied">
                {t('checkout:couponCodeApplied')}
              </Text.Body3Strong>
            </Box>
          </Animation.FadeIn>
        ) : (
          <Animation.FadeIn duration={200}>
            <Box display="flex" justifyContent="center" alignItems="flex-start" columnGap={8}>
              <Grid.Row width={{ _: 209, sm: 233 }} position="relative" flexDirection="column">
                <Box pb={{ _: 8, md: 'unset' }}>
                  <CouponInputTitle>{t('checkout:inputFields.coupon.label')}</CouponInputTitle>
                  <CouponInput
                    data-testid="checkout-coupon-input"
                    onClick={() => trackCustomGA4Event({ eventName: GA4_EVENTS.couponClick })}
                    onBlur={() => trackCustomGA4Event({ eventName: GA4_EVENTS.couponFocusOut })}
                    hasError={!!couponError}
                    value={couponToApply}
                    type="text"
                    name="couponCodeInput"
                    onChange={(event) => {
                      setCouponToApply(event.target.value);
                    }}
                    onKeyUp={(event) => {
                      if (event.key === 'Enter' && couponToApply) {
                        setCouponToApply(event.target.value);
                        clickApplyHandler(event.target.value, handleSuccess, handleError);
                      }
                    }}
                  />
                  {!!couponError && (
                    <Text.Body3 data-testid="coupon-error" color="danger">
                      {t(`checkout:errors.${couponError}`)}
                    </Text.Body3>
                  )}
                </Box>
              </Grid.Row>

              <Box width={111}>
                <Button
                  dataTestId="coupon-apply-button"
                  fullWidth
                  type="button"
                  color={isCouponApplied() ? 'secondary' : 'primary'}
                  disabled={couponToApply === couponCode || !couponToApply}
                  onClick={() => clickApplyHandler(couponToApply, handleSuccess, handleError)}
                >
                  {isCouponApplied()
                    ? t('checkout:couponButton.applied')
                    : t('checkout:couponButton.apply')}
                </Button>
              </Box>
            </Box>
          </Animation.FadeIn>
        ))}
    </ThemeProvider>
  );
};

const CouponRedeem = ({ isLoading, couponCode, couponError, clickApplyHandler }) => (
  <Box maxWidth={1200} mx="auto" mt={24} mb={48}>
    <Grid.Col size={{ md: 8, lg: 4 }} mx="auto" px={{ _: 16, md: 24 }}>
      <CouponRedeemField
        isLoading={isLoading}
        couponCode={couponCode}
        couponError={couponError}
        clickApplyHandler={clickApplyHandler}
      />
    </Grid.Col>
  </Box>
);

export default CouponRedeem;

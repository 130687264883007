import styled from 'styled-components';

// Changing import to resolve unresolved Text component when building netlifyCMS
import { Text } from '@components/typography';

export default styled(Text.Body4)`
  position: absolute;
  background: white;
  top: -0.8em;
  left: 10px;
  padding: 0 4px;
`;

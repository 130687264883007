import styled from 'styled-components';

export default styled('input')`
  border: ${({ hasError, theme }) =>
    hasError ? `solid 1px ${theme.colors.danger}` : `solid 1px ${theme.colors.primary}`};
  padding: 0 14px;
  border-radius: 4px;
  height: 42px;
  font-size: 14px;
  font-family: ${({ theme }) => theme?.fonts?.default};
  font-weight: 500;
  width: 100%;

  &::placeholder {
    color: #b3b3c9;
  }

  &:focus {
    outline: unset;
  }
  &:hover {
    border: solid 1px #858585;
  }
`;
